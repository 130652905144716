import React from 'react'
import { logo } from 'images'
import { MEDIA_QUERY } from 'styles'
import { css } from 'emotion'

const LogoView = ({ className }) => (
  <img className={css(styles.logo, className)} src={logo} alt="Ebisu" />
)

const styles = {
  logo: css({
    cursor: 'pointer',
    width: '15rem',
    objectFit: 'contain',
    '&:hover': {
      transform: 'scale(1.03)',
    },
    [MEDIA_QUERY.MD]: {
      width: '20rem',
    },
    [MEDIA_QUERY.SM]: {
      width: '15rem',
    },
  }),
}

export default LogoView
