import React, { Component } from 'react'
import { css } from 'emotion'
import { CFLink, CFView, Space, Text } from 'components'
import { appStore, googlePlay, facebook, instagram, twitter } from 'images'
import { MEDIA_QUERY } from 'styles'

class Contact extends Component {
  render() {
    const { HoursElement, LogoElement, ContactUsElement } = this.props
    return (
      <div className={styles.container}>
        <div className={styles.column}>
          {LogoElement}
          <Space height="4rem" />
          <CFView w="25rem">
            <Text h6 dark content>
              Our spacious, high-ceiling dining area can seat up to 250
              customers. We are located right on the intersection of Robson &
              Bute street on the 2nd floor! We welcome parties of all sizes and
              are here to celebrate any occasion! Hope to see you soon!
            </Text>
          </CFView>
          <Space height="4rem" />
          <div className={styles.buttonBar}>
            <CFLink href="https://itunes.apple.com/ca/app/id1445109313">
              <img className={styles.mobile} src={appStore} alt="App Store" />
            </CFLink>
            <Space w2 />
            <CFLink href="https://play.google.com/store/apps/details?id=tech.codefusion.ebisu">
              <img
                className={styles.mobile}
                src={googlePlay}
                alt="Goodle Play"
              />
            </CFLink>
          </div>
        </div>
        <Space width="4rem" height="4rem" />
        <div className={styles.column}>
          <Text heading h2 red bold>
            BUSINESS HOURS
          </Text>
          <Space h2 />
          <div className={styles.hours}>{HoursElement}</div>
        </div>
        <Space width="4rem" height="4rem" />
        <div className={styles.column}>
          <Text heading h2 red bold>
            CONTACT US
          </Text>
          <Space h2 />
          {ContactUsElement}
          <Space height="4rem" />
          <Text heading h2 red bold>
            SOCIAL MEDIA
          </Text>
          <Space h2 />
          <CFView row>
            <CFLink href="https://www.facebook.com/ebisurobson/">
              <img className={styles.mediaIcon} src={facebook} alt="Facebook" />
            </CFLink>
            <CFLink href="https://www.instagram.com/explore/locations/624432/ebisu-on-robson/?hl=en">
              <img
                className={styles.mediaIcon}
                src={instagram}
                alt="Instagram"
              />
            </CFLink>
            <CFLink href="https://twitter.com/EbisuVancouver">
              <img className={styles.mediaIcon} src={twitter} alt="Twitter" />
            </CFLink>
          </CFView>
        </div>
      </div>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    maxWidth: '120rem',
    padding: '4rem',
    display: 'flex',
    justifyContent: 'center',
    [MEDIA_QUERY.MD]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '2rem',
    },
  }),
  hours: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '35rem',
  }),
  mobile: css({
    height: '4rem',
    objectFit: 'contain',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.03)',
    },
  }),
  column: css({
    marginTop: '4rem',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [MEDIA_QUERY.MD]: {
      alignItems: 'center',
      marginTop: '0',
    },
  }),
  day: css({
    width: '6rem',
  }),
  buttonBar: css({
    display: 'flex',
  }),
  mediaIcon: css({
    marginRight: '2rem',
    width: '6rem',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  }),
}

export default Contact
