import React from 'react'
import { css } from 'emotion'
import { CFView, Text } from 'components'
import { categoryCircle, categoryCircleSelected, charLogo } from 'images'
import { COLOR, MEDIA_QUERY } from 'styles'

const CategoryItemView = ({ imageUrl, isSelected, nameFormatted }) => (
  <div className={styles.container}>
    <div className={styles.imageContainer}>
      <img
        className={styles.circle}
        src={isSelected ? categoryCircleSelected : categoryCircle}
        alt="Circle"
      />
      {!!imageUrl ? (
        <img className={styles.image} src={imageUrl} alt="Category" />
      ) : (
        <img className={styles.image} src={charLogo} alt="Category" />
      )}
    </div>
    <CFView fill center>
      <Text
        painting
        h4
        color={isSelected ? COLOR.red : COLOR.white}
        center
        className={styles.text}
      >
        {nameFormatted}
      </Text>
    </CFView>
  </div>
)

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    height: '20vw',
    width: '20vw',
    maxHeight: '21rem',
    maxWidth: '21rem',
    marginTop: '0.5rem',
    [MEDIA_QUERY.MD]: {
      height: '43vw',
      width: '43vw',
    },
  }),
  imageContainer: css({
    height: '80%',
    width: '80%',
    position: 'relative',
    '&:hover': {
      transform: 'scale(1.03)',
    },
    [MEDIA_QUERY.MD]: {
      height: '75%',
      width: '75%',
    },
  }),
  image: css({
    width: '70%',
    left: '13%',
    top: '13%',
    objectFit: 'contain',
    borderRadius: '10rem',
    position: 'absolute',
    zIndex: 2,
  }),
  circle: css({
    height: '100%',
    position: 'absolute',
    zIndex: 1,
  }),
  text: css({
    zIndex: 4,
  }),
}

export default CategoryItemView
