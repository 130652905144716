import React from 'react'
import { orderOnlineButton } from 'images'

export default () => (
  <img
    src={orderOnlineButton}
    alt="Order Now"
    style={{ cursor: 'pointer', width: '300px' }}
  />
)
