import React from 'react'
import { css } from 'emotion'
import PageFooter from './PageFooter'
import Hero from './Hero'
import Promotions from './Promotions'
import About from './About'
import Contact from './Contact'
import KameiGroup from './KameiGroup'
import { Space } from 'components'
import { woodbg, mountainbamboobg } from 'images'
import { MEDIA_QUERY } from 'styles'
import WelcomeModal from './WelcomeModal'

function getLocationType(locationId) {
  switch (locationId) {
    case 'ebisuLocation1':
    case 'm7qncyLXqHn57UcgxNGT':
      return 'robson'
    case 'ebisuLocation2':
      return 'broadway'
    case 'ebisuLocation3':
      return 'richmond'
    default:
      return 'robson'
  }
}

class HomeView extends React.Component {
  render() {
    const {
      HoursElement,
      ContactUsElement,
      OrderNowButtonElement,
      LogoElement,
      locationId,
      phoneNumber,
    } = this.props
    const locationType = getLocationType(locationId)
    return (
      <section className={styles.container}>
        <Hero OrderNowButtonElement={OrderNowButtonElement} />
        <Space h2 />
        <Promotions />
        <div className={styles.bamboo}>
          <Space h2 />
          <About />
          <Space h2 />
          <Contact
            HoursElement={HoursElement}
            LogoElement={LogoElement}
            ContactUsElement={ContactUsElement}
          />
          <Space h2 />
        </div>
        <PageFooter />
        {/* <WelcomeModal /> */}
      </section>
    )
  }
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: `url(${woodbg}) no-repeat`,
  }),
  bamboo: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: `url(${mountainbamboobg}) no-repeat`,
    backgroundPosition: 'top',
    backgroundSize: '100% 100%',
    [MEDIA_QUERY.MD]: {
      backgroundPosition: 'bottom',
      backgroundSize: 'contain',
    },
  }),
}

export default HomeView
