import React from 'react'
import { Space, Text } from 'components'
import { css } from 'emotion'
import { MEDIA_QUERY } from 'styles'

const SummaryContainerView = ({
  isCartEmpty,
  waitTime,
  SummaryElement,
  DeliveryElement,
  NotesInputElement,
  SubmitButtonElement,
}) => {
  if (isCartEmpty) {
    return (
      <div className={styles.cart}>
        <Text content h1 white center className={styles.emptyText}>
          Cart is empty. <br />
          Add items from the Menu.
        </Text>
      </div>
    )
  }
  return (
    <div className={styles.cart}>
      {DeliveryElement}
      <Space h2 />
      {SummaryElement}
      <Space h2 />
      {NotesInputElement}
      <Space h1 />
      <Text content h4 white>
        * Additional charges may apply for Special Requests
      </Text>
      <Space h3 />
      <div className={styles.button}>{SubmitButtonElement}</div>
    </div>
  )
}

const styles = {
  cart: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  }),
  button: css({
    alignSelf: 'center',
  }),
  emptyText: css({
    position: 'relative',
    bottom: '14rem',
    [MEDIA_QUERY.LG]: {
      bottom: '0',
    },
  }),
}

export default SummaryContainerView
