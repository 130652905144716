import React from 'react'
import { css } from 'emotion'

const RadioButtonView = ({ checked }) => (
  <div className={styles.radio}>
    {checked && <div className={styles.checkedRadioButton} />}
  </div>
)

const styles = {
  radio: css({
    cursor: 'pointer',
    height: '3rem',
    width: '3rem',
    border: '5px solid',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  }),
  checkedRadioButton: css({
    height: '1rem',
    width: '1rem',
    borderRadius: '50%',
    backgroundColor: 'white',
  }),
}

export default RadioButtonView
