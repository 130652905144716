import React, { Component } from 'react'
import { css } from 'emotion'
import { CFView, Text } from 'components'
import {
  aboutImage1,
  aboutImage2,
  forkIcon,
  dividerLeft,
  dividerRight,
} from 'images'
import { MEDIA_QUERY } from 'styles'

class About extends Component {
  render() {
    return (
      <div className={styles.container}>
        <CFView row justifyCenter alignCenter>
          <img className={styles.divider} src={dividerLeft} alt="divider" />
          <Text className={styles.headerText} superhero dark dafoe>
            Discover Our Restaurant
          </Text>
          <img
            className={styles.dividerRight}
            src={dividerRight}
            alt="dividerRight"
          />
        </CFView>
        <CFView className={styles.about}>
          <img className={styles.banner} src={aboutImage1} alt="About" />
          <img className={styles.banner} src={aboutImage2} alt="About" />
          <div className={styles.descriptionContainer}>
            <Text className={styles.headerText2} hero dark dafoe>
              About us
            </Text>
            <CFView>
              <img
                className={styles.image}
                src={forkIcon}
                alt="Fork and Knife"
              />
            </CFView>
            <Text className={styles.description} h4 dark content>
              Vancouver's original Japanese Restaurant Lounge featuring some of
              the best deals for food & drinks in town! Delicious tapas and
              inventive, original sushi created by our master chefs straight
              from Japan! The trendy modern design of our spacious interior,
              many big screens and a huge projection TV help to set the perfect
              ambiance for any occasion from quick business lunches, intimate
              romantic dinners or large parties of any size!
            </Text>
          </div>
        </CFView>
      </div>
    )
  }
}

const styles = {
  container: css({
    display: 'flex',
    width: '100%',
    maxWidth: '130rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '0 2rem 2rem 2rem',
    position: 'relative',
    marginTop: '4rem',
    [MEDIA_QUERY.LG]: {
      marginTop: 0,
    },
    [MEDIA_QUERY.MD]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
  about: css({
    display: 'flex',
    marginTop: '6rem',
    [MEDIA_QUERY.MD]: {
      margin: 0,
      flexDirection: 'column-reverse',
    },
  }),
  descriptionContainer: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingBottom: '2rem',
    [MEDIA_QUERY.MD]: {
      paddingBottom: '0',
    },
  }),
  banner: css({
    objectFit: 'cover',
    width: '33%',
    height: '100%',
    marginRight: '3rem',
    boxShadow: '.3rem .3rem .4rem rgba(0,0,0,.6)',
    [MEDIA_QUERY.MD]: {
      marginTop: '3rem',
      marginRight: '0',
      width: '100%',
      objectFit: 'cover',
    },
  }),
  image: css({
    objectFit: 'contain',
    width: '3rem',
    [MEDIA_QUERY.LG]: {
      display: 'none',
    },
    [MEDIA_QUERY.MD]: {
      display: 'none',
    },
  }),
  headerText: css({
    [MEDIA_QUERY.MD]: {
      fontSize: '3.5rem',
    },
  }),
  headerText2: css({
    paddingBottom: '1.5rem',
    [MEDIA_QUERY.LG]: {
      paddingBottom: '0',
    },
    [MEDIA_QUERY.MD]: {
      fontSize: '2.5rem',
    },
  }),
  description: css({
    paddingTop: '1.5rem',
    [MEDIA_QUERY.LG]: {
      fontSize: '1.4rem',
    },
    [MEDIA_QUERY.MD]: {
      fontSize: '1.5rem',
    },
  }),
  divider: css({
    width: '8%',
    objectFit: 'contain',
    marginRight: '5rem',
    [MEDIA_QUERY.MD]: {
      display: 'none',
    },
  }),
  dividerRight: css({
    width: '8%',
    objectFit: 'contain',
    marginLeft: '5rem',
    [MEDIA_QUERY.MD]: {
      display: 'none',
    },
  }),
}

export default About
