export * from 'cf-core/src/images'
export const aboutImage1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/aboutImage1.jpg'
export const aboutImage2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/aboutImage2.jpg'
export const dividerLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/dividerLeft.png'
export const dividerRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/dividerRight.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const bamboobg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/bamboobg.webp'
export const categoryCircle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/categoryCircle.png'
export const categoryCircleSelected =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/categoryCircleSelected.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cflogo.png'
export const ebisuRedLogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1589330580/ebisu/ebisuLogo.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/facebook.png'
export const footerbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/footerbg.png'
export const forkIcon =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/forkIcon.png'
export const forkIconWhite =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/forkIconWhite.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/instagram.png'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/logo.png'
export const logowhite =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/logowhite.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/pointsMeter.png'
export const promobg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/promobg.png'
export const promotion1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/promotion1.png'
export const promotion2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/promotion2.png'
export const promotion3 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/promotion3.png'
export const reservationButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/reservationButton.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/rewardArrowRight.png'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/rewardbg.png'
export const rewardMiso =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/rewardMiso.png'
export const rewardSalad =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/rewardSalad.png'
export const rewardspromobg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/rewardspromobg.jpg'
export const rewardSushi =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/rewardSushi.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/redeemButton.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/rewardDefault.png'
export const twinMobileApp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/twinMobileApp.png'
export const twitter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/twitter.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const charLogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554360314/ebisu/Ebisu_Logo_Transparent_Black.png'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1589608707/ebisu/downloadButton.png'
export const reserveButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1596088259/ebisu/reserveButton.png'
export const orderOnlineButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1589608706/ebisu/orderOnlineButton.png'
export const woodbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562517159/ebisu/woodbg.jpg'
export const mountainbamboobg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562517025/ebisu/mountainbamboobg.jpg'
export const mountainbg2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562053941/ebisu/mountainbg2.jpg'
export const navbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562516923/ebisu/navbg.jpg'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562053935/ebisu/hero.jpg'
export const robson =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1565718394/Kamei/robson.jpg'
export const broadway =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1565718394/Kamei/broadway.jpg'
export const richmond =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1565718394/Kamei/richmond.jpg'
export const baru =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1565718394/Kamei/baru.jpg'
export const royale =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1565718394/Kamei/royale.jpg'
export const kameiWhite =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1647882684/ebisu/Logo_White.png'
export const promoButtonbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/Kamei/promobg.png'
export const viewMenuButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1589609209/ebisu/viewMenuButton.png'
// export const lunchMenu =
//   'https://res.cloudinary.com/codefusiontech/image/upload/v1647881923/ebisu/lunch03212022.pdf'
// export const happyHourMenu =
//   'https://res.cloudinary.com/codefusiontech/image/upload/v1647881923/ebisu/HH03212022.pdf'
// export const specialsMenu =
//   'https://res.cloudinary.com/codefusiontech/image/upload/v1596088025/ebisu/Seasonal_Special.jpg'
// export const drinksMenu =
//   'https://res.cloudinary.com/codefusiontech/image/upload/v1632360645/ebisu/Drink092221.pdf'
// export const dinnerMenu =
//   'https://res.cloudinary.com/codefusiontech/image/upload/v1648082911/ebisu/Dinner092221.pdf'

export { default as virusmessage } from './virusmessage.jpg'
export { default as happyHourMenu } from './HH03232024.pdf'
export { default as lunchMenu } from './lunch062023.pdf'
export { default as dinnerMenu } from './dinner062023.pdf'
export { default as drinksMenu } from './drink082022.pdf'
export { default as specialsMenu } from './specialMenu11012023.pdf'
