import React, { Component } from 'react'
import { css } from 'emotion'
import { CFLink, CFView, Space } from 'components'
import HomeHeader from './HomeHeader'
import { hero, downloadButton, reserveButton, viewMenuButton } from 'images'
import { MEDIA_QUERY } from 'styles'

class Hero extends Component {
  render() {
    const { OrderNowButtonElement } = this.props
    return (
      <div className={styles.container}>
        <HomeHeader />
        <CFView className={styles.buttonContainer}>
          <Space w2 h1 />
          <CFLink href="https://www.opentable.ca/ebisu-on-robson-reservations-vancouver?restref=139015&lang=en-CA&ot_source=Restaurant%20website">
            <img
              src={reserveButton}
              alt="Reserve a Table"
              className={styles.buttons}
            />
            {/* <img
              src={viewMenuButton}
              alt="Reserve a Table"
              className={styles.buttons}
            /> */}
          </CFLink>
          <Space w2 />
          <div className={styles.buttons}>{OrderNowButtonElement}</div>
          <Space w2 />
          <CFLink href="http://onelink.to/8m57vg">
            <img
              src={downloadButton}
              alt="Download App"
              className={styles.buttons}
            />
          </CFLink>
        </CFView>
      </div>
    )
  }
}

const styles = {
  container: css({
    height: '100vh',
    width: '100%',
    background: `url(${hero}) no-repeat`,
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    position: 'relative',
    boxShadow: '1px 1px 5px rgba(0,0,0,.7)',
    [MEDIA_QUERY.MD]: {
      backgroundAttachment: 'scroll',
      height: '100vh',
    },
  }),
  buttonContainer: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    maxWidth: '120rem',
    paddingTop: '.5rem',
    paddingBottom: '3rem',
    bottom: '0',
    right: '50%',
    transform: 'translateX(50%)',
    [MEDIA_QUERY.MD]: {
      flexDirection: 'column',
      height: '20rem',
    },
  }),
  buttons: css({
    width: '300px',
    '&:hover': {
      transform: 'scale(1.01)',
    },
    '&:active': {
      transform: 'translateY(-1px)',
    },
  }),
}

export default Hero
