// For Googlebot (SEO)
import 'babel-polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import EbisuApp from './EbisuApp'
// import StyleGuide from './styles/StyleGuide'
import { unregister } from './registerServiceWorker'

ReactDOM.render(<EbisuApp />, document.getElementById('root'))
unregister()
