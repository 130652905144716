import React, { Component } from 'react'
import { css } from 'emotion'
import { CFLink, CFView, Space, Text } from 'components'
import {
  promobg,
  promotion1,
  promotion2,
  promotion3,
  forkIconWhite,
  dividerLeft,
  dividerRight,
  happyHourMenu,
  drinksMenu,
  specialsMenu,
  lunchMenu,
  dinnerMenu,
  kameiWhite,
  promoButtonbg,
} from 'images'
import { MEDIA_QUERY } from 'styles'

class Promotions extends Component {
  render() {
    return (
      <div className={styles.container}>
        <CFView row justifyCenter alignCenter>
          <img className={styles.divider} src={dividerLeft} alt="divider" />
          <Text className={styles.headerText} superhero dark dafoe>
            Discover Our Menus
          </Text>
          <img
            className={styles.dividerRight}
            src={dividerRight}
            alt="dividerRight"
          />
        </CFView>
        <div className={styles.row}>
          <div className={styles.promoContainer}>
            <CFLink>
              <div className={styles.promo}>
                <Text dafoe h1 gold>
                  Discover
                </Text>
                <Text content h3 white center xBold>
                  DINNER
                </Text>
                <Space h2 />
                <CFLink className={styles.menuContainer} href={dinnerMenu}>
                  <button className={styles.menu}>DINNER MENU</button>
                </CFLink>
                <Space height="4.8rem" />
                {/* <CFLink className={styles.menuContainer} href={lunchMenu}>
                  <button className={styles.menu}>LUNCH MENU</button>
                </CFLink> */}
                <img
                  className={styles.divide}
                  src={kameiWhite}
                  alt="Kamei Divide"
                />
                <img className={styles.image} src={promotion1} alt="Promo 1" />
              </div>
            </CFLink>
          </div>
          <div className={styles.promoContainer}>
            <CFLink>
              <div className={styles.promo}>
                <Text dafoe h1 gold>
                  Discover
                </Text>
                <Text content h3 white center xBold>
                  DRINKS
                </Text>
                <Space h2 />
                <CFLink className={styles.menuContainer} href={drinksMenu}>
                  <button className={styles.menu}>DRINK MENU</button>
                </CFLink>
                <Space height="4.7rem" />
                <img
                  className={styles.divide}
                  src={kameiWhite}
                  alt="Kamei Divide"
                />
                <img className={styles.image} src={promotion2} alt="Promo 2" />
              </div>
            </CFLink>
          </div>
          <div className={styles.promoContainer}>
            <CFLink>
              <div className={styles.promo}>
                <Text dafoe h1 gold>
                  Discover
                </Text>
                <Text content h3 white center xBold>
                  OUR SPECIALS
                </Text>
                <Space h2 />
                <CFLink className={styles.menuContainer} href={happyHourMenu}>
                  <button className={styles.menu}>HAPPY HOUR MENU</button>
                </CFLink>
                <Space height="1.2rem" />
                <CFLink className={styles.menuContainer} href={specialsMenu}>
                  <button className={styles.menu}>SEASONAL SPECIALS</button>
                </CFLink>
                <img
                  className={styles.divide}
                  src={kameiWhite}
                  alt="Kamei Divide"
                />
                <img className={styles.image} src={promotion3} alt="Promo 3" />
              </div>
            </CFLink>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '130rem',
    marginTop: '3rem',
    [MEDIA_QUERY.MD]: {
      overflowY: 'hidden',
      overflowX: 'auto',
    },
  }),
  row: css({
    marginTop: '6rem',
    display: 'flex',
    padding: '0 4rem 0 4rem',
    justifyContent: 'space-between',
    width: '100%',
    [MEDIA_QUERY.MD]: {
      alignItems: 'center',
      marginTop: '2rem',
      flexDirection: 'column',
    },
  }),
  promoContainer: css({
    width: '27%',
    height: '44rem',
    background: `url(${promobg}) no-repeat`,
    backgroundSize: '100% 100%',
    [MEDIA_QUERY.MD]: {
      minWidth: '80vw',
    },
  }),
  promo: css({
    padding: '2.8rem 4rem 4rem 4rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  image: css({
    width: '48%',
    borderRadius: '50%',
  }),
  divide: css({
    paddingTop: '2rem',
    paddingBottom: '1.5rem',
    width: '12%',
    objectFit: 'contain',
  }),
  divider: css({
    width: '8%',
    objectFit: 'contain',
    marginRight: '5rem',
    [MEDIA_QUERY.MD]: {
      display: 'none',
    },
  }),
  dividerRight: css({
    width: '8%',
    objectFit: 'contain',
    marginLeft: '5rem',
    [MEDIA_QUERY.MD]: {
      display: 'none',
    },
  }),
  headerText: css({
    [MEDIA_QUERY.MD]: {
      fontSize: '4rem',
    },
  }),
  menu: css({
    width: '20rem',
    height: '3.5rem',
    color: 'black',
    fontSize: '1.8rem',
    border: 'none',
    borderRadius: '.3rem',
    fontFamily: 'antonio',
    background: `url(${promoButtonbg}) no-repeat`,
    backgroundPosition: 'center',
    boxShadow: '2px 2px 3px rgba(0,0,0,1)',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.03)',
    },
    '&:active': {
      transform: 'translateY(-1.5px)',
    },
    '&:focus': {
      outline: 'none',
    },
  }),
}

export default Promotions
