import React, { Component } from 'react'
import { css } from 'emotion'
import { Text, CFView, CFLink } from 'components'
import { robson, broadway, richmond, baru, royale } from 'images'
import { MEDIA_QUERY } from 'styles'

class KameiGroup extends Component {
  render() {
    return (
      <div className={styles.container}>
        <CFView className={styles.header}>
          <Text className={styles.headerText}>KAMEI RESTAURANT GROUP</Text>
        </CFView>
        <div className={styles.row}>
          <CFLink href={'http://robson.ebisu.ca/'}>
            <img src={robson} className={styles.image} alt="Ebisu Robson" />
          </CFLink>
          <CFLink href={'http://www.ebisu.ca/broadway'}>
            <img src={broadway} className={styles.image} alt="Ebisu Broadway" />
          </CFLink>
          <CFLink href={'http://www.ebisu.ca/richmond'}>
            <img src={richmond} className={styles.image} alt="Ebisu Richmond" />
          </CFLink>
          <CFLink href={'https://kameibaru.ca/'}>
            <img src={baru} className={styles.image} alt="Kamei Baru" />
          </CFLink>
          <CFLink href={'http://www.kameiroyale.com/'}>
            <img src={royale} className={styles.image} alt="Kamei Royale" />
          </CFLink>
        </div>
      </div>
    )
  }
}

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'black',
    justifyContent: 'center',
  }),
  header: css({
    marginTop: '2rem',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  headerText: css({
    fontFamily: 'raleway',
    fontSize: '2.4rem',
    color: 'white',
    textAlign: 'center',
  }),
  row: css({
    margin: '2rem 0',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [MEDIA_QUERY.MD]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
  image: css({
    width: '25em',
    height: '20rem',
    objectPosition: 'center',
    objectFit: 'cover',
    '&:hover': {
      transform: 'scale(1.05)',
    },
    [MEDIA_QUERY.MD]: {
      width: '100%',
    },
  }),
}

export default KameiGroup
