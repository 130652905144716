import React from 'react'
import { css } from 'emotion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { COLOR } from 'styles'

const AddButtonView = ({ disabled }) => (
  <FontAwesomeIcon
    icon="plus-circle"
    className={styles.button}
    style={disabled && { color: COLOR.grey }}
  />
)

const styles = {
  button: css({
    color: COLOR.red,
    cursor: 'pointer',
    fontSize: '2.5rem',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  }),
}

export default AddButtonView
