import React, { Component } from 'react'
import { css } from 'emotion'
import { NavbarView } from 'components'
import { MEDIA_QUERY } from 'styles'
import { LocationSelect, Logo } from 'cf-web-app/components'
import { mountainbg2 } from 'images'

class PageHeader extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.location}>
            <Logo />
            <LocationSelect />
          </div>
          <NavbarView />
        </div>
      </div>
    )
  }
}

const styles = {
  container: css({
    width: '100%',
    height: '10rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [MEDIA_QUERY.SM]: {
      background: `url(${mountainbg2}) no-repeat`,
      backgroundPosition: 'bottom',
      backgroundSize: 'cover',
      height: '13rem',
    },
  }),
  innerContainer: css({
    width: '90%',
    height: '100%',
    maxWidth: '116rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [MEDIA_QUERY.SM]: {
      paddingTop: '2rem',
      flexDirection: 'column',
      height: '13rem',
    },
  }),
  location: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [MEDIA_QUERY.SM]: {
      flexDirection: 'column',
    },
  }),
}

export default PageHeader
