import React, { Component } from 'react'
import { css } from 'emotion'
import {
  CFButton,
  CFLink,
  CFModal,
  CFText,
  CFView,
  Space,
} from 'cf-web-app/components'
import { PrimaryButtonView } from 'components'
import { ebisuRedLogo } from 'images'
import { MEDIA_QUERY } from 'styles'

export default class WelcomeModal extends Component {
  state = {
    showModal: false,
  }
  componentDidMount() {
    // const welcomeSeen = localStorage.getItem('welcomeSeen')
    // if (!welcomeSeen) {
    this.setState({ showModal: true })
    // }
  }
  render() {
    return (
      <CFModal isOpen={this.state.showModal}>
        <CFView className={styles.container}>
          <img
            src={ebisuRedLogo}
            className={styles.message}
            alt="Coronavirus update"
          />
          <CFView h3 className={styles.text}>
            <CFView mb="20px">
              We are excited to announce we are open for Dine-in! Please see
              below for Temporary Hours.
            </CFView>
            <CFView mb="20px">
              In respect to social distancing and our customers safety -
              Capacity will be limited, please book early.
            </CFView>
            <CFView mb="20px">Thank you for your continued support!</CFView>
          </CFView>
          <CFView mt="1rem" row center className={styles.button}>
            <CFView mb="2rem">
              <CFButton onClick={this._handleConfirmClick}>
                <PrimaryButtonView label="OK, GOT IT !" />
              </CFButton>
            </CFView>
          </CFView>
        </CFView>
      </CFModal>
    )
  }
  _handleConfirmClick = () => {
    this.setState({ showModal: false })
    localStorage.setItem('welcomeSeen', true)
  }
}

const styles = {
  container: css({
    width: '650px',
    border: '2px solid #D2B283',
    borderRadius: '5px',
    backgroundColor: '#232323',
    color: 'white',
    display: 'flex',
    padding: '2rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [MEDIA_QUERY.MD]: {
      margin: '4rem',
      padding: '4rem',
      width: '100vw',
    },
  }),
  text: css({
    textAlign: 'left',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    [MEDIA_QUERY.SM]: {
      padding: 0,
      fontSize: 20,
      textAlign: 'left',
      width: '100%',
    },
  }),
  message: css({
    width: '200px',
    marginBottom: '20px',
    [MEDIA_QUERY.SM]: {
      width: '48vw',
    },
  }),
  button: css({
    [MEDIA_QUERY.SM]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
}
